import axios from './axios.service'

const method = 'signature-positions'

class SignatureJobService {
  async index(queryData) {
    try {
      const response = await axios.get(`/${method}`, {
        params: { ...queryData }
      })
      return response.data
    } catch (error) {
      return error
    }
  }

  async show(id) {
    try {
      const response = await axios.get(`/${method}/${id}`)
      return response.data
    } catch (error) {
      return error
    }
  }

  async delete(rowClicked) {
    try {
      const response = await axios.delete(`/${method}/${rowClicked}`)
      return response
    } catch (error) {
      return error.response
    }
  }

  async store(data) {
    try {
      const response = await axios({
        method: 'post',
        url: `/${method}`,
        data
      })
      return response
    } catch (error) {
      return error.response
    }
  }

  async update(id, data) {
    try {
      const response = await axios({
        method: 'put',
        url: `/${method}/${id}`,
        data
      })
      return response
    } catch (error) {
      return error.response
    }
  }
}

export default new SignatureJobService()
